<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn text icon justify="end" v-bind="attrs" v-on="on" title="Filtros">
          <v-icon>mdi-filter-outline</v-icon>
        </v-btn>
        <v-btn
          text
          icon
          justify="end"
          v-show="hasActiveFilter"
          @click="removeFilters"
          title="Remover filtros"
        >
          <v-icon>mdi-filter-remove-outline</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-select
                  no-data-text="Nenhum dado encontrado"
                  v-model="selected"
                  :items="situations"
                  label="Situação"
                  @change="selectSituation"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "RequestsTableFilters",
  data: () => ({
    selected: null,
    dialog: false,
    situations: [
      "Em cadastramento",
      "Em análise",
      "Pendente",
      "Aprovada",
      "Rejeitada",
      "Cancelada",
      "Cancelada Imobiliária",
    ],
  }),
  computed: {
    hasActiveFilter() {
      return this.selected !== null;
    },
  },
  created() {
    if(sessionStorage.getItem(process.env.VUE_APP_REQUESTS_SITUATION_FILTER_KEY)) {
      this.selected = sessionStorage.getItem(process.env.VUE_APP_REQUESTS_SITUATION_FILTER_KEY)
    }
  },
  methods: {
    selectSituation(val) {
      this.$emit("filterSituation", val);
      this.dialog = false;
      sessionStorage.setItem(
        process.env.VUE_APP_REQUESTS_SITUATION_FILTER_KEY,
        val
      )
    },
    removeFilters() {
      this.selected = null;
      this.$emit("resetFilters");
      sessionStorage.removeItem(process.env.VUE_APP_REQUESTS_SITUATION_FILTER_KEY)
    },
  },
};
</script>
