<template>
  <div>
    <v-card elevation="6" class="pa-3">
      <v-subheader class="ma-0 pa-0">
        <h4 class="bold primary--text">Solicitações</h4>
      </v-subheader>
      <br />
      <v-data-table
        :headers="tableHeaders"
        :items="items"
        sort-by="request"
        class="elevation-0"
        :items-per-page="10"
        no-data-text="Nenhum dado encontrado"
        no-results-text="Nenhum resultado encontrado"
        :footer-props="{
          'items-per-page-text': 'Itens por página:',
          'items-per-page-all-text': 'Todos',
          'items-per-page-options': [5, 10, 15, 20, -1],
          'page-text': '{0}-{1} de {2}',
        }"
        :search="search"
        v-if="!reloadTable"
        :loading="loading"
        loading-text="Carregando dados..."
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-row>
              <v-col md="4">
                <v-row>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Pesquisar..."
                    single-line
                    hide-details
                  ></v-text-field>
                </v-row>
              </v-col>
              <v-col md="1">
                <v-row justify="center">
                  <RequestsTableFilters
                    @filterSituation="filterSituation"
                    @resetFilters="resetTable"
                  ></RequestsTableFilters>
                </v-row>
              </v-col>
              <v-col align="right">
                <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  to="/request/new"
                  rounded
                  v-if=isRealEstateUser
                  @click.native="resetRequest"
                >
                  Nova solicitação
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template #item.numero="{ item }">
          <v-btn
            color="primary"
            text
            class="pa-0"
            style="text-decoration: underline"
            @click="isRealEstateUser && (inRegistration(item) || withPendency(item)) ?  $router.push({ name: 'EditRequest', params: { id: item.id } }) : $router.push({ name: 'RequestAbstract', params: { id: item.id } })"
          >
            {{ item.numero }}
          </v-btn>
        </template>
        <template #item.locatario="{ item }">
          <span class="textLocatario">{{ item.locatario }}</span>
        </template>
        <template #item.situacao="{ item }">
          <span style="white-space: nowrap;">
            <v-chip small :color="getColorBySituacaoId(item.situacao_id)" class="white--text">
              {{ item.situacao }}
            </v-chip>
            <RequestPendenciesDialog
              v-if="item.pendencias && showPendencies(item)"
              :pendencieId=item.id
            ></RequestPendenciesDialog>
          </span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import RequestsTableFilters from './TableFilters'
import RequestPendenciesDialog from '@/components/Request/PendenciesDialog'

export default {
  name: 'RequestsTable',
  components: {
    RequestsTableFilters,
    RequestPendenciesDialog
  },
  data: () => ({
    search: '',
    reloadTable: false,
    items: [],
    loading: true,
    trGray: false
  }),
  computed: {
    ...mapGetters([
      'isRealEstateUser',
      'isGSuser',
      'requestsList',
      'situationsList',
      'serviceTypeList'
    ]),
    tableHeaders () {
      if (this.isRealEstateUser) {
        return [
          { text: 'Solicitação', value: 'numero' },
          { text: 'Código do Imóvel', value: 'codigo' },
          { text: 'Endereço', value: 'endereco' },
          { text: 'Locatários', value: 'locatario' },
          { text: 'Atendente', value: 'atendente' },
          { text: 'Data Solicitação', value: 'data_solicitacao' },
          { text: 'Situação', value: 'situacao' }
          // { text: "Serviço", value: "servico"},
          // { text: "Ações", value: "actions", sortable: false},
        ]
      }

      return [
        { text: 'Solicitação', value: 'numero' },
        { text: 'Código do Imóvel', value: 'codigo' },
        { text: 'Endereço', value: 'endereco' },
        { text: 'Locatários', value: 'locatario' },
        { text: 'Imobiliária', value: 'imobiliaria' },
        { text: 'Data Solicitação', value: 'data_solicitacao' },
        { text: 'Situação', value: 'situacao' }
        // { text: 'Ações', value: 'actions', sortable: false }
        // { text: "Serviço", value: "servico"},
        // ,
      ]
    }
  },
  created () {
    this.initialize()
  },
  methods: {
    ...mapActions([
      'getSituationsList',
      'getServiceTypeList',
      'getRequestsList',
      'getRequest'
    ]),
    ...mapMutations([
      'resetRequest',
      'resetRequestsList'
    ]),
    setRequestsList () {
      // eslint-disable-next-line no-undef
      this.items = _.cloneDeep(this.requestsList)
    },
    initialize () {
      this.getSituationsList()
      this.getServiceTypeList()
      this.getRequestsList().then(res => {
        this.setRequestsList()
        if (sessionStorage.getItem(process.env.VUE_APP_REQUESTS_SITUATION_FILTER_KEY)) {
          this.filterSituation(sessionStorage.getItem(process.env.VUE_APP_REQUESTS_SITUATION_FILTER_KEY))
        }
        this.loading = false
      })
    },
    tableReload () {
      this.reloadTable = true
      this.reloadTable = false
    },
    resetTable () {
      this.setRequestsList()
      this.tableReload()
    },
    filterSituation (val) {
      this.setRequestsList()
      this.items = this.requestsList.filter((c) => {
        return c.situacao === val
      })
      this.tableReload()
    },
    inRegistration (item) {
      return this.exists(item.situacao_id) && item.situacao_id === 1
    },
    withPendency (item) {
      return this.exists(item.situacao_id) && item.situacao_id === 3
    },
    showPendencies (item) {
      return this.exists(item.situacao_id) && (item.situacao_id === 2 || item.situacao_id === 3 || item.situacao_id === 5)
    },
    getColorBySituacaoId (id) {
      switch (id) {
        case 1:
          return 'primarydark'
        case 2:
          return 'alert'
        case 3:
          return 'error'
        case 4:
          return 'success'
        case 5:
          return 'darkgray'
        case 6:
          return 'darkgray'
        case 7:
          return 'darkgray'
        default:
          return ''
      }
    }
  }
}
</script>

<style>
.textLocatario {
  font-weight: bold;
}
</style>
